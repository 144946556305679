<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: '0.00',
        discount: '0.00',
        shipping: {
          method: '',
          value: '0.00',
          address: {
            zipcode: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
          }
        },
        payment: {
          methods: [],
          list: [],
        },

        total: '0.00',

        items: null,
        status: null,
      },

      comments: {
        modal: false,
        loading: false,
        list: null,
        body: '',
      },

      balance: {
        modal: false,
        loading: false,
        pay: '0.00',
        total: '0.00',
      },
    };
  },
  validations: {
    comments: {
      body: { required, minLength: minLength(5) }
    },
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('store/orders/' + this.order.id)
          .then(response => {
            if (response.data.status == 'success') {
              this.order = response.data.order

              this.getBalance()

              this.loading = false
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    getComments() {
      api
        .get('store/orders/comments/' + this.order.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.comments.list = response.data.list
          } else {
            this.comments.list = [];
          }
        })
        .catch(error => {
          if (error) {
            this.comments.list = [];
          }
        })
    },
    showComment() {
      this.comments.modal = false;
      this.comments.modal = true;
    },
    addComment() {
      if (this.comments.body) {
        this.comments.loading = true

        api
          .post('store/orders/comments/' + this.order.id, {
            body: this.comments.body
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.comments.body = ''
              this.getComments()

              this.comments.loading = false
              this.comments.modal = false
            } else {
              this.$toast.error(response.data.message)
            }
          })
          .catch(error => {
            if (error) {
              this.comments.loading = false
            }
          })
          .finally(() => {
            this.comments.loading = false
          })
      }
    },
    getBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.balance.total = response.data.balance
            this.balance.pay = parseFloat(this.balance.total) - parseFloat(this.order.total);
          }
        })
    },
    payOrder() {
      this.balance.loading = true;

      api
        .post('wallet/pay/' + this.order.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.$toast.success('O pedido foi pago com sucesso.')
            this.getOrder();
          }
        })
        .catch(error => {
          if (error) {
            this.balance.loading = false;
          }
        })
        .finally(() => {
          this.balance.loading = false;
        })
    }
  },
  mounted() {
    this.getOrder()
    this.getComments()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Order') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">
                  #{{ order.id }}
                </h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t('Item') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Price') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Qty') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Total') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td>{{ td.name }} </td>
                      <td>{{ td.price | currency }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3 v-if="order.type == 'receive'" class="font-size-16 mb-3">{{ t('Endereço de entrega') }}</h3>
                    <h3 v-else class="font-size-16 mb-3">{{ t('Endereço de retirada') }}</h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement">{{ order.shipping.address.complement }}, </span>
                    {{ order.shipping.address.district }}<br>
                    {{ order.shipping.address.city }}/{{ order.shipping.address.state }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a v-if="order.shipping.tracking && order.shipping.tracking.code" target="_blank" :href="order.shipping.tracking.url" class="btn btn-default btn-sm mt-1 p-2 align-middle">
                    <i class="bx bxs-truck font-size-17 text-white mr-1 align-top"></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">{{ t('Detalhes do pagamento') }}</h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t('SUBTOTAL') }}</td>
                          <td class="px-0 py-1 text-right">{{ order.subtotal | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1" v-if="order.shipping.method">{{ t('FRETE') }} — {{ order.shipping.method }}</td>
                          <td class="px-0 py-1" v-else>{{ t('FRETE') }}</td>
                          <td class="px-0 py-1 text-right">+ {{ order.shipping.value | currency }}</td>
                        </tr>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t('DESCONTO') }}</td>
                          <td class="px-0 py-1 text-right">- {{ order.discount | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">{{ t('TOTAL') }}</td>
                          <td class="px-0 py-1 font-weight-bold text-right">{{ order.total | currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t('Status') }}</h3>
              <div class="mb-4">
                <div v-for="(td, index) in order.status" :key="index">
                  {{ td.date }} — {{ t(td.status) }}
                </div>
              </div>
              <h3 class="font-size-16 mb-2">
                Observações do pedido
                <button class="btn btn-default btn-sm px-2 py-1" v-on:click="showComment();">Adicionar</button>
              </h3>
              <div class="mb-5">
                <div v-for="(td, index) in comments.list" :key="index">
                  <div class="border rounded p-3 mb-3">
                    <div class="font-size-12">{{ td.date }}</div>
                    <div class="mt-2" v-html="td.body"></div>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">Informações para pagamento</h3>
              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-3" content-class="p-3 text-muted">
                <b-tab v-for="(pay, method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'pix'" class="text-center">
                    <div class="mt-4" v-if="pay.pix && pay.pix.copy_paste && pay.pix.qrcode">
                      <div>
                        Use o código QR para prosseguir com a transação.
                      </div>
                      <div class="my-4 d-flex justify-content-center">
                        <div class="border rounded p-3">
                          <img style="width: 180px" :src="pay.pix.qrcode" />
                        </div>
                      </div>
                      <div>
                        <button class="btn btn-default btn-lg btn-block" v-clipboard:copy="pay.pix.copy_paste"
                          v-on:click="$toast.success('<strong>Copiado para a área de transferência.</strong><br>Agora, tudo o que você precisa fazer é acessar o aplicativo do seu banco e concluir o processo usando o <strong>Pix Copia e Cola</strong>.')">
                          Pix Copia e Cola
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="method == 'pagarme'" class="mt-4 text-center">
                    <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-pagarme.svg"><br>
                    <a target="_blank" class="btn btn-default btn-lg btn-block" :href="pay.url">Clique aqui para pagar</a>
                  </div>
                  <div v-else-if="method == 'mercadopago'" class="mt-4 text-center">
                    <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-mercadopago.svg"><br>
                    <a target="_blank" class="btn btn-default btn-lg btn-block" :href="pay.url">Clique aqui para pagar</a>
                  </div>
                </b-tab>
                <b-tab key="balance">
                  <template v-slot:title>Saldo em Conta</template>
                  <div class="mt-4 text-center">
                    <template v-if="balance.pay >= 0">
                      <p>Deseja pagar esse pedido com o seu saldo?</p>
                      <p>Seu saldo atual é de <strong>{{ balance.total | currency }}</strong> e seu saldo após o pagamento será de <strong>{{ balance.total - order.total | currency }}</strong>.</p>
                      <button class="btn btn-default btn-lg mr-3" v-on:click="payOrder()">
                        Confirmar
                        <b-spinner v-if="balance.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                      </button>
                    </template>
                    <template v-else>
                      <p>No momento você não possui saldo suficiente para realizar o pagamento do pedido.</p>
                    </template>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="comments.modal" title="Adicionar observação" centered>
      <b-form @submit.prevent="addComment">
        <b-form-group :label="t('Mensagem')">
          <b-form-textarea v-model="comments.body" rows="5" max-rows="6" :class="{ 'is-invalid': $v.comments.body.$error }"></b-form-textarea>
          <div v-if="$v.comments.body.$error" class="invalid-feedback">
            <span v-if="!$v.comments.body.required || !$v.comments.body.minLength">A mensagem é obrigatória</span>
          </div>
        </b-form-group>
        <b-button :disabled="comments.loading == true || this.comments.body.length < 5 || $v.comments.$error" type="submit" variant="default" class="btn-lg">
          Enviar
          <b-spinner v-if="comments.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>